<template>
        <nav class="sticky top-0 bg-gray-900 mobile-nav z-50" :class="{'mobile-nav-opened': mobile_open}">
            <div class="max-w-7xl mx-auto px-2">
                <div class="relative">
                    <div class="p-2 inset-y-0 left-0 flex justify-end gap-3 items-center">
                        <div class="flex justify-between gap-5 top-menu-divider flex-row-reverse w-full">
                            <button type="button" @click="mobile_open = !mobile_open" class="mr-1 inline-flex items-center justify-center text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                                <span class="sr-only">{{$t('translations.components.navigation-mobile-pos.open-menu')}}</span>
                                <svg class="block h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                                <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>

                            <router-link
                            :to="{ name: 'mobilepos.recent-transactions' }"
                            class="inline-flex items-center justify-center text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                            >
                                <svg class="h-6 h-6" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"/>
                                </svg>
                            </router-link>

                            <div class="flex items-center flex-grow" :class="{'justify-between flex-grow flex-row-reverse': showBackButton}" >

                                <!--// back to tables button-->
                                <button v-if="showBackButton" @click.prevent="$router.back()" class="inline-flex items-center justify-center text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-current="page">
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" fill="currentColor"
                                        viewBox="0 0 492 492" style="enable-background:new 0 0 492 492; transform: rotate(-90deg);" xml:space="preserve" class="w-5 h-5">
                                        <g>
                                            <g>
                                                <path d="M442.627,185.388L265.083,7.844C260.019,2.78,253.263,0,245.915,0c-7.204,0-13.956,2.78-19.02,7.844L49.347,185.388
                                                    c-10.488,10.492-10.488,27.568,0,38.052l16.12,16.128c5.064,5.06,11.82,7.844,19.028,7.844c7.204,0,14.192-2.784,19.252-7.844
                                                    l103.808-103.584v329.084c0,14.832,11.616,26.932,26.448,26.932h22.8c14.832,0,27.624-12.1,27.624-26.932V134.816l104.396,104.752
                                                    c5.06,5.06,11.636,7.844,18.844,7.844s13.864-2.784,18.932-7.844l16.072-16.128C453.163,212.952,453.123,195.88,442.627,185.388z"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </button>

                                <div class="flex items-center justify-center text-2xl font-bold scrollbar-hide flex-grow">
                                    <h1 @click="$emit('titleclicked')" class="flex flex-grow">
                                        <div>
                                            <div class="">{{ title ? title : '' }}</div>
                                        </div>
                                        <div class="ml-2 truncate" v-if="clerk.id"> - {{ clerk.name }}</div>
                                    </h1>
                                </div>
                            </div>

                            <slot name="right"></slot>
                        </div>
                        <slot></slot>
                    </div>
                </div>
            </div>

            <!-- Mobile menu, show/hide based on menu state. -->
            <div class="bg-gray-900 absolute w-full" :class="{'hidden': !mobile_open}" id="mobile-menu">
                <div class="mobile-fade-closer bg-gray-500 opacity-50"></div>
                <div class="px-2 pt-2 pb-3">
                    <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                    <router-link @click="mobile_open = !mobile_open" v-if="!minimal"
                                :to="{ name: 'mobilepos.areas' }"
                                class="bg-gray-900 text-white mb-2 block px-3 py-2 rounded-md text-base font-medium"
                                aria-current="page">{{$t('translations.components.navigation-mobile-pos.areas')}}</router-link>

                    <!-- admin -->
                    <modal-admin @click="mobile_open = !mobile_open" />


                    <div class="bg-gray-900 text-white mb-2 block px-3 py-2 rounded-md text-base font-medium cursor-pointer" @click="reload()">Reload</div>

                    <!-- <div class="bg-gray-900 text-white mb-2 block px-3 py-2 rounded-md text-base font-medium cursor-pointer" @click="$refs.walletaddcredit.open()">Kaart opladen</div> -->


                    <modal-logout :mobile="true" />
                </div>
            </div>



        </nav>

</template>

<script>
// import Status from '../components/Status.vue';

import ModalLogout from '../components/ModalLogout.vue';

export default {
    name: 'navigation.mobile.pos',
    props: ['title', 'minimal', 'current_page'],
    components: {
        // Status,
        ModalLogout,
        // ModalSettings,
        // ModalTransactions,
    },
    data() {
        return {
            mobile_open: false,
        }
    },
    methods: {
        reload() {
            localStorage.removeItem('masterdata.terminal');
            localStorage.removeItem('masterdata.settings');
            localStorage.removeItem('masterdata.items');
            localStorage.removeItem('masterdata.discounts');
            localStorage.removeItem('masterdata.paymenttypes');

            location.reload();
        },
    },
	computed: {
		location() {
			return this.$store.getters['location/location'];
        },
		terminal() {
			return this.$store.getters['terminal/terminal'];
        },
		clerk() {
			return this.$store.getters['transaction/clerk'];
        },
		isMobile() {
			return this.$store.getters['terminal/isMobile'];
        },
        showBackButton() {
            return ['table', 'recent-transactions'].includes(this.current_page);
        },
    }
}
</script>
